import axios from 'axios';
import updaterEvents from '../felles/updaterEvents';

export function post(url, data, success) {
    data = JSON.stringify(data);

    axios({
        data,
        headers: {
            'Content-Type': 'application-json'
        },
        method: 'post',
        url
    })
        .then(resp => {
            success(null, resp.data);
            return resp.data;
        })
        .catch(error => {
            success(error);
        });
}

export function get(url, success) {
    return axios
        .get(url, {
            headers: {
                'Content-Type': 'application-json'
            }
        })
        .then(resp => {
            const json = resp.data;
            if (json) {
                // denne brukes for å logge ut brukeren
                if (json.logOut) {
                    window.location.assign('/loggut.php');

                    return;
                }

                // denne kan også brukes til å tvinge klinten til å reloade
                if (json.forceRefresh) {
                    updaterEvents.emit('refresh');

                    return;
                }

                // denne kan også brukes til å tvinge klinten til å reloade
                // ved gammel versjon
                if (json.forceVersionRefresh) {
                    updaterEvents.emit('refresh', json.builds);

                    return;
                }

                updaterEvents.emit('update', json.builds);
            }
            if (success) {
                success(null, json);
            }

            return json;
        })
        .catch(error => {
            if (success) {
                success(error);
            }

            return error;
        });
}
