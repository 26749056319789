export function el(tag, params) {
    const domNode = document.createElement(tag);

    if (params.name !== undefined && params.name !== null) {
        domNode.name = params.name;
    }

    if (params.id) {
        domNode.id = params.id;
    }

    if (params.class) {
        domNode.className = params.class;
    }

    if (params.text) {
        domNode.textContent = params.text;
    }

    if (params.children) {
        params.children.forEach(child => {
            if (child) {
                domNode.appendChild(child);
            }
        });
    }

    return domNode;
}

export function find(el, selector) {
    return el.querySelector(selector);
}

export function button(params) {
    params.class = params.class
        ? `btn-reset btn ${params.class}`
        : 'btn-reset btn';

    const btn = el('button', params);

    if (params.type) {
        btn.type = params.type;
    } else {
        btn.type = 'submit';
    }

    return btn;
}

export function remove(node) {
    if (node.parentNode) {
        node.parentNode.removeChild(node);
    }
}

export function $(selector) {
    return find(document, selector);
}
