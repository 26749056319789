import swedish from '../../public/locale/sv/LC_MESSAGES/messages.json';
import { get, set } from '../util/storage';

const localStorageKey = 'localization';
class Translator {
    constructor() {
        this.dictionary = { SE: swedish };
        this.t = this.t.bind(this);
        const savedModel = get(localStorageKey);

        if (savedModel) {
            this.locale = savedModel;
        }
    }

    setLocale(locale) {
        this.locale = locale;
        set(localStorageKey, locale);
    }

    t(keyword) {
        if (
            this.dictionary[this.locale] &&
            this.dictionary[this.locale][keyword]
        ) {
            return this.dictionary[this.locale][keyword];
        }
        return keyword;
    }
}
export const translator = new Translator();

export const _ = keyword => translator.t(keyword);
