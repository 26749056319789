import * as Sentry from '@sentry/browser';
import { _ } from "./translator";

let release = null;

if (window.SENTRY_RELEASE) {
    release = window.SENTRY_RELEASE.id;
}

Sentry.init({
    allowUrls: [/\w*\.?hemswx\.\w{2}/],

    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    dialogOptions: {
        errorFormEntry: _(
            'Noen felter har feilaktig data. Korriger dem gjerne og prøv igjen'
        ),
        errorGeneric: _(
            'Noe gikk galt ved insendning av din rapport, prøv igjen senere'
        ),
        labelClose: _('Lukk'),
        labelComments: _('Kommentar'),
        labelEmail: _('Epost'),
        labelName: _('Navn'),
        labelSubmit: _('Send'),
        subtitle: _('Support har blitt varlset.'),
        subtitle2: _(
            'Vi setter stort pris på hvis du tar dig tid til å fortelle oss som skjedde, i skjemaet nedan.'
        ),
        successMessage: _('Takk for din feedback!'),
        title: _('Ojda, vi verkar har problemer.')
    },
    dsn:
        'https://7d86cdb346544f918051feb3754edf88@o1109013.ingest.sentry.io/6272709',
    // eslint-disable-next-line compat/compat
    environment: window.location.hostname,
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        /DataCloneError/
    ],
    // if your build tool supports it.
    integrations: [],
    release,
    showDialog: true,
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
    tunnel: '/tunnel.php'
});
