/* global localStorage */
// module som wrapper localStorage og håndterer feil

export function get(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        return null;
    }
}
export function set(key, obj) {
    try {
        if (typeof obj === 'string') {
            return localStorage.setItem(key, obj);
        }

        return localStorage.setItem(key, JSON.stringify(obj));
    } catch (e) {
        return null;
    }
}
export function remove(key) {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
}
