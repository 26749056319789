// modul som lager ett login form
import { el, button } from './dom';
import { _ } from '../felles/translator';

function labelEl(params) {
    const { forAttr, id, text } = params;

    const node = el('label', {
        class: params.class,
        id,
        text
    });

    node.setAttribute('for', forAttr);

    return node;
}

function checkbox(insert) {
    if (insert) {
        const inputName = 'remember';

        const input = el('input', {
            class: 'mr1',
            id: inputName,
            name: inputName
        });

        input.type = 'checkbox';

        return el('div', {
            children: [
                input,
                labelEl({
                    forAttr: inputName,
                    text: _('Husk meg på denne maskinen')
                })
            ],
            class: 'mb3'
        });
    }
}

function inputEl(params) {
    const { id, name, type, value } = params;

    const node = el('input', {
        class: params.class,
        id,
        name
    });

    node.type = type || 'text';
    node.value = value || '';
    node.required = true;
    node.setAttribute('autocomplete', 'off');
    node.setAttribute('autocorrect', 'off');

    return node;
}

export default function({
    buttonText,
    error,
    fieldId,
    label,
    step,
    type,
    value
}) {
    const inputLabel = labelEl({
        class: 'db mb1 b',
        forAttr: fieldId,
        id: `label-${fieldId}`,
        text: label
    });

    const input = inputEl({
        class: 'input w-100 input-reset db ba f5 pa2 br0',
        id: fieldId,
        name: fieldId,
        type,
        value
    });

    const errorP = error
        ? el('p', {
              class: 'mt2 hwx-red',
              id: `error-${fieldId}`,
              text: error
          })
        : null;

    const buttonEl = button({
        class: 'btn-blue ph4',
        id: 'submit',
        text: buttonText
    });

    const formEl = el('form', {
        children: [
            el('div', {
                children: [inputLabel, input, errorP],
                class: 'mb3'
            }),
            checkbox(step !== 'user' && step !== 'base_user'),
            buttonEl
        ]
    });

    formEl.setAttribute('novalidate', 'novalidate');
    formEl.method = 'post';
    if (step !== 'user') {
        formEl.action = '/logginn/login-password3.php';
        formEl.appendChild(
            inputEl({
                name: 'second',
                type: 'hidden',
                value: step
            })
        );
    }

    return formEl;
}
