import '../../css/hemswx.css';
import '../../css/menu3.css';
// script for å kunne kjøre innlogging
import morphdom from 'morphdom';
import { translator, _ } from '../felles/translator';

import { el, $, find, remove } from './dom';
import { post } from '../util/request';
import loginForm from './loginForm';
import '../felles/SentryVanilla';
// eslint-disable-next-line compat/compat
const tld = window.location.origin.split('.').pop();
if (tld === 'se') {
    translator.setLocale('SE');
}

function pText(step, pinSent = 'sms') {
    if (step === 'pin' || step === 'pinThirdStep') {
        return _(`Bruk den 4 sifrede koden du får på ${  pinSent  } fra oss.`);
    } if (step === 'password') {
        return _('Skriv inn passordet ditt.');
    } if (step === 'base_user') {
        return (
            _('Skriv inn mobilnummeret ditt. ') +
            state.user +
            _(
                ` er en fellesbruker. Du må derfor bekrefte innloggingen med ditt eget mobilnummer for å få en kode på ${pinSent}`
            )
        );
    }
}

function labeText(step, loginType, pinSent = 'sms') {
    if (step === 'user') {
        return loginType === 'userOnly'
            ? _('Mobilnummer/brukernavn')
            : _('Brukernavn');
    } if (step === 'pin' || step === 'pinThirdStep') {
        return _('Kode fra ') + pinSent;
    } if (step === 'password') {
        return _('Ditt passord');
    } if (step === 'base_user') {
        return _('Ditt mobilnummer');
    }
}

function type(step) {
    switch (step) {
        case 'password':
            return 'password';
        case 'pin':
        case 'pinThirdStep':
            return 'tel';
        default:
            return 'text';
    }
}

function btnText(step) {
    return step === 'user' || step === 'base_user' ? _('Neste') : _('Logg inn');
}

function section(state) {
    const { error, loginType, password, pinSent, step, user } = state;
    const value = step === 'user' ? user : password;

    return el('section', {
        children: [
            el('p', {
                class: 'mb3',
                text: pText(step, pinSent)
            }),
            loginForm({
                buttonText: btnText(step),
                error,
                fieldId:
                    step === 'pin' ||
                    step === 'password' ||
                    step === 'pinThirdStep'
                        ? 'password'
                        : 'step',
                label: labeText(step, loginType, pinSent),
                step,
                type: type(step),
                value
            })
        ]
    });
}
function get(name) {
    if (
        (name = new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`).exec(
            window.location.search
        ))
    )
        return decodeURIComponent(name[1]);
}
const step = get('step');
const state = {
    step: step || 'user',
    user: ''
};
const formSection = $('#login');

const loginPost = (user, params, cb) => {
    params.user = encodeURI(user);

    post('logginn/login-user3.php', params, (error, json) => {
        // håndter ingen respons fra server
        if (error || !json) {
            cb(new Error(_('Tjenesten er nede. Prøv igjen senere')));

            return;
        }

        // håndter feilmeldig
        if (json.error_message) {
            cb(new Error(json.error));

            return;
        }

        cb(null, json);
    });
};

if (formSection) {
    // login type selected
    formSection.addEventListener('submit', evt => {
        const {value} = find(formSection, 'input');
        const formBtn = find(formSection, 'button');

        formBtn.setAttribute('disabled', 'disabled');
        const errorElem = document.getElementById('errorMessage');
        if (errorElem) {
            remove(errorElem);
        }
        state.error = null;

        if (state.loginType === 'userOnly') {
            if (state.step === 'user') {
                evt.preventDefault();

                state.user = value;

                if (value === null || value === '' || value === undefined) {
                    state.error = _('Du må fylle inn mobilnummer/bruker');

                    render();
                } else {
                    formBtn.classList.add('btn-spinner');
                    loginPost(value, {}, (error, json) => {
                        if (error) {
                            state.error = error;

                            render();
                        } else if (json.redirect) {
                            window.location.assign(json.redirect);
                        } else {
                            state.id = json.id;
                            if (json.second === 'base_user') {
                                state.error = `${_(
                                    'Du må logge på som basebruker med dette brukernavnet. '
                                )}\n${_(
                                    'Vennligst trykk "Neste" hvis brukernavnet er riktig.'
                                )}`;
                                state.step = 'user';
                                state.loginType = 'baseUser';
                            } else {
                                state.step = json.second;
                            }
                            state.billett = json.billett;
                            state.pinSent = json.pin_sendt;

                            render();
                        }
                    });
                }
            } else {
                state.password = value;

                if (value === null || value === '' || value === undefined) {
                    evt.preventDefault();

                    state.error =
                        _('Du må fylle inn ') +
                        (state.step === 'pin'
                            ? _('kode du fikk på SMS')
                            : _('passordet ditt'));

                    render();
                } else {
                    formBtn.classList.add('btn-spinner');
                }
            }
        } else if (state.step === 'base_user') {
            // loginType = 'baseUser'

            evt.preventDefault();

            state.base_user = value;

            if (value === null || value === '' || value === undefined) {
                state.error = _('Du må fylle inn mobilnummer');

                render();
            } else {
                formBtn.classList.add('btn-spinner');
                loginPost(
                    value,
                    {
                        action: 'verify',
                        billett: state.billett,
                        id: state.id
                    },
                    (error, json) => {
                        if (error) {
                            state.error = error;
                        } else {
                            state.step = json.second;
                            state.billett = json.billett;
                            state.verify_id = json.verify_id;
                            state.pinSent = json.pin_sendt;
                        }

                        render();
                    }
                );
            }
        } else if (state.step === 'user') {
            evt.preventDefault();

            state.user = value;

            if (value === null || value === '' || value === undefined) {
                state.error = _('Du må fylle inn mobilnummer/bruker/epost');

                render();
            } else {
                formBtn.classList.add('btn-spinner');
                loginPost(value, {}, (error, json) => {
                    if (error) {
                        state.error = error;
                    } else if (json.second === 'password') {
                        state.error = `${_(
                            'Du må logge på som vanlig bruker med dette brukernavnet. '
                        )}\n${_(
                            'Vennligst trykk "Neste" hvis brukernavnet er riktig.'
                        )}`;
                        state.step = 'user';
                        state.loginType = 'userOnly';
                    } else {
                        state.id = json.id;
                        state.step = json.second;
                        state.billett = json.billett;
                        state.pinSent = json.pin_sendt;
                    }

                    render();
                });
            }
        } else {
            state.password = value;
            if (value === null || value === '' || value === undefined) {
                evt.preventDefault();

                state.error =
                    _('Du må fylle inn ') +
                    (state.step === 'pin'
                        ? _('kode du fikk på SMS')
                        : _('passordet ditt'));

                render();
            } else {
                formBtn.classList.add('btn-spinner');
            }
        }
    });
}

function render() {
    /* console.log('render, loginType is ' + state.loginType); */
    const newFormSection = section(state);

    morphdom(formSection, newFormSection, {
        childrenOnly: true
    });

    find(formSection, 'input').focus();
}

if (formSection) {
    // login type selected, render adjusted form - login type select handled in PHP
    state.loginType = $('#loginType').value;
    render();
}
